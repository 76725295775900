import React from "react"
import Header from "../templates/header-template.jsx"
import Footer from "../templates/footer-template.jsx"
import Banner from "../templates/banner-template.jsx"
import { Box, Container } from "@chakra-ui/react"

const Four0Four = () => (
  <div>
    <Header />
    <Banner title="404 Not Found"></Banner>
    <Container maxW="container.lg" py="40px"></Container>
    <Box mt="50px" bg="#145091">
      <Footer notHome />
    </Box>
  </div>
)

export default Four0Four
